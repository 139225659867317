import * as React from "react"

import Seo from "../components/seo"
import Slider from "../components/privacypolicy"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Privacy Policy | Smartlink Communications'
        
        description='Privacy policy for Smartlink Communications '

        keywords='Privacy Policy,  Smartlink Communications, Smartlink, Radu Magdin, Radu Nicola Delicote'

      />

<Helmet>






</Helmet>



<Slider />
     
    </div>
  )

}


export default mainPage;